<template>
  <div class="about">
    <navHeader :index="4"></navHeader>
    <div class="picWrapper">
      <img class="indexTopBg" :src="require(`@/assets/images/commonUse/bg.png`)" alt="" />
      <img class="indexTopBg1" :src="require(`@/assets/images/commonUse/bg1.png`)" alt="" />
    </div>
    <div class="mainContent">
      <div class="mainWrapper">
        <!-- <div class="title">关于尚阳</div> -->
        <div class="title">
          <i class="iconfont icon-guanyushangyang"></i>
        </div>
        <div class="text">
          <span class="textColor">尚阳网络</span>
          <span>
            深耕智慧农业多年，有丰富的项目经验与智慧农业解决方案。我们旨在将先进的信息化和智能化技术与农业生产技术相结合，力求通过对环境的精准监控，
            设施设备的智能控制，以及专业的技术服务，帮助生产者进行科学高效的农事生产，促进农产品品质与产量提升，帮助农民最大化产值与利润。
          </span>
        </div>
        <div class="columnBox">
          <div class="leftColumn">
            <img class="columnPic" :src="require(`@/assets/images/about/about1.png`)" alt="" />
            <div class="columnContent">
              <div class="subTitle">联系我们</div>
              <div class="subText">service@ecoprobio.cn</div>
              <div class="subText">400-806-1696</div>
              <div class="subText">浙江省嘉善县罗星街道白水塘西路 1918 号</div>
            </div>
          </div>
          <div class="leftColumn">
            <img class="columnPic" :src="require(`@/assets/images/about/about2.png`)" alt="" />
            <div class="columnContent">
              <div class="subTitle">定制化服务</div>
              <div class="subText">
                我们能为您提供个性化的定制服务，定制周期短，您将需求告知我们，我们会为您提供一套合适的解决方案。
              </div>
            </div>
          </div>
        </div>
        <div class="title">我们的优势</div>
        <div class="listBox">
          <div class="listItem" v-for="(item, index) in list" :key="index">
            <div class="listItemLeft">
              <img class="columnPic" :src="item.pic" alt="" />
              <img class="columnPic-m" :src="item.pic" alt="" :style="item.style" />
            </div>
            <div class="listItemRight">
              <div class="rightTitle">{{ item.title }}</div>
              <div class="rightText">
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <rightsFooter></rightsFooter>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import navHeader from '@/components/navHeader.vue'
import rightsFooter from '@/components/rightsFooter.vue'

export default {
  name: 'About',
  components: { navHeader, rightsFooter },
  data() {
    return {
      list: [
        {
          title: '专业且贴身的定制化服务',
          text: '尚阳网络自成立起就专注于数字农业板块，为全国不同规模、不同细分行业的农场、企业、科研机构、政府和垂直细分服务商提供专业化的定制解决方案。通过大量行业客户和项目的积累，在不同细分市场有着相当丰富的行业经验。',
          pic: require(`@/assets/images/about/i1.png`),
          style: 'width: 31.305px;height: 36px;'
        },
        {
          title: '行业经验积淀',
          text: '每个交付的温室项目都是独一无二的，但每个尚阳网络的温室解决方案都基于久经考验并值得新来的温室项目模型。',
          pic: require(`@/assets/images/about/i2.png`),
          style: 'width: 36px;height: 36px;'
        },
        {
          title: '敏捷业务模式',
          text: '自尚阳网络创立起采用敏捷管理模式，加强项目管理、项目实施的弹性高效，专注于项目快速落地。',
          pic: require(`@/assets/images/about/i3.png`),
          style: 'width: 28px;height: 32px;'
        },
        {
          title: '优秀的技术专长',
          text: '尚阳网络的优势之一是我们拥有高水平专业知识的员工，包括拥有多年一线种植经验和农场管理经验的农业专家，具有国际PMP认证的专业项目经理以及具有一线大厂资历的IT工程师，包括资深架构师、高级售前工程师、电气工程师、全栈工程师、实施工程师等，并在温室项目等领域拥有多项专利及其他技术成果。',
          pic: require(`@/assets/images/about/i4.png`),
          style: 'width: 32px;height: 22.2px;'
        },
        {
          title: '专业保障',
          text: '在尚阳网络，对质量的承诺是一个核心价值。从我们的员工开始，我们毫不妥协的标准，适用于我们业务的方方面面，并致力于提供高质量的产品和服务。',
          pic: require(`@/assets/images/about/i5.png`),
          style: 'width: 28.5px;height: 29.1px;'
        }
      ]
    }
  },
  computed: {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
.about {
  // padding-bottom: 100px;
  .picWrapper {
    max-width: 100%;
    // background: #fff;
    .indexTopBg {
      width: 100%;
      height: 680px;
      object-fit: cover;
    }
    .indexTopBg1 {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }
  @media only screen and (max-width: 1199px) {
    .picWrapper {
      max-width: 100%;
      // background: #fff;
      .indexTopBg {
        display: none;
      }
      .indexTopBg1 {
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .picWrapper {
      .indexTopBg {
      }
      .indexTopBg1 {
        display: none;
      }
    }
  }

  .mainContent {
    width: 100%;
    display: flex;
    justify-content: center;
    .mainWrapper {
      width: 1200px;
      .title {
        font-family: HarmonyOS Sans SC;
        font-size: 40px;
        font-weight: bold;
        letter-spacing: 0em;

        color: #0e293b;
        margin: 80px 0 24px 0;
        .iconfont {
          font-size: 40px;
        }
      }
      .text {
        font-family: HarmonyOS Sans SC;
        font-size: 16px;
        font-weight: 300;
        line-height: 32px;
        text-align: justify; /* 浏览器可能不支持 */
        letter-spacing: 0em;

        color: #0e293b;
        margin-bottom: 64px;
        .textColor {
          font-weight: 700;
        }
      }
      .columnBox {
        width: 100%;
        display: grid;
        grid-gap: 40px;
        grid-template-columns: repeat(2, auto); /* 总共三列，占据剩余空间的比例相等 */
        grid-template-rows: repeat(1, auto); /* 总共两行，自适应高度 */
        .leftColumn {
          width: 580px;
          height: 820px;
          display: flex;
          flex-direction: column;
          .columnPic {
            width: 580px;
            height: 580px;
          }
          .columnContent {
            width: 580px;
            height: 240px;
            background: #0e293b;
            padding: 56px 0 0 92px;
            box-sizing: border-box;
            .subTitle {
              font-family: HarmonyOS Sans SC;
              font-size: 24px;
              font-weight: bold;
              line-height: 24px;
              letter-spacing: 0em;

              color: #ffffff;
              margin-bottom: 24px;
            }
            .subText {
              font-family: HarmonyOS Sans SC;
              font-size: 16px;
              font-weight: 300;
              line-height: 32px;
              letter-spacing: 0em;
              // margin-bottom: 16px;
              color: #ffffff;
            }
          }
        }
      }
      .listBox {
        width: 100%;
        // margin-bottom: 80px;
        // display: flex;
        .listItem {
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;
          .listItemLeft {
            border-radius: 16px;
            width: 160px;
            height: 160px;
            // margin-right: 16;
            background: #f2f3ee;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
            }
          }
          .listItemRight {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 1024px;
            height: 160px;
            border-radius: 16px;
            background: #f2f3ee;
            padding: 0px 24px 0 24px;
            box-sizing: border-box;
            .rightTitle {
              font-family: HarmonyOS Sans SC;
              font-size: 24px;
              font-weight: bold;
              line-height: 24px;
              letter-spacing: 0em;

              color: #0e293b;
              margin-bottom: 10px;
            }
            .rightText {
              font-family: HarmonyOS Sans SC;
              font-size: 16px;
              font-weight: 300;
              line-height: 32px;
              letter-spacing: 0em;

              color: #0e293b;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1199px) {
    .mainContent {
      .mainWrapper {
        width: 100%;
        .title {
          margin: 10.6vw 5.33vw 5.33vw 5.33vw;
        }
        .text {
          font-size: 14px;
          font-weight: 300;
          line-height: 24px;
          margin: 0vw 5.33vw 5.33vw 5.33vw;
          .textColor {
          }
        }
        .columnBox {
          grid-template-columns: repeat(1, auto);
          grid-template-rows: repeat(2, auto);
          .leftColumn {
            width: 100%;
            height: 100%;
            align-items: center;
            .columnPic {
              width: 89.33vw;
              height: 89.33vw;
            }

            .columnContent {
              width: 89.33vw;
              height: 52.27vw;

              padding: 10.6vw 9.6vw 0 9.6vw;
              box-sizing: border-box;
              .subTitle {
                font-size: 20px;
                line-height: 20px;
                font-weight: 600;
              }
              .subText {
                font-size: 14px;
                font-weight: 300;
                line-height: 24px;
              }
            }
          }
        }
        .listBox {
          padding: 0 5.33vw 0 5.33vw;
          box-sizing: border-box;
          .listItem {
            flex-direction: column;
            .listItemLeft {
              margin-bottom: 12px;
              width: 80px;
              height: 80px;
              .columnPic {
                display: none;
              }
              .columnPic-m {
              }
            }
            .listItemRight {
              width: 100%;
              height: 100%;
              padding: 16px 16px 16px 16px;
              box-sizing: border-box;
              .rightTitle {
              }
              .rightText {
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    .mainContent {
      .mainWrapper {
        .title {
        }
        .text {
          .textColor {
          }
        }
        .columnBox {
          .leftColumn {
            .columnPic {
            }
            .columnPic-m {
              display: none;
            }
            .columnContent {
              .subTitle {
              }
              .subText {
              }
            }
          }
        }
        .listBox {
          .listItem {
            .listItemLeft {
              .columnPic {
              }
              .columnPic-m {
                display: none;
              }
            }
            .listItemRight {
              .rightTitle {
              }
              .rightText {
              }
            }
          }
        }
      }
    }
  }
}
</style>